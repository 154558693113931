import { Container } from '@/components/Container/Container';
import { UserReview } from '@/components/UserReview/UserReview';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useFindAllFilledEvaluations } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useState } from 'react';
import { TestimonialsQuotesIcon } from '../../Icons/TestimonialsIcon/TestimonialsQuotesIcon';
import IconWrapper from '../../IconWrapper/IconWrapper';
import { Anchor } from '../../UI/Anchor';
import styles from './Reviews.module.scss';

interface ReviewCardProps {
  content: string;
  author: string;
  githubHandle: string;
  githubUrl: string;
  avatarUrl: string;
}

const ReviewCard = ({
  content,
  author,
  githubHandle,
  githubUrl,
  avatarUrl,
}: ReviewCardProps) => (
  <div className={styles.reviewCard}>
    <div className={styles.quoteIcon}>
      <IconWrapper
        icon={TestimonialsQuotesIcon}
        className={styles.icon}
      />
    </div>
    <p className={styles.content}>{content}</p>
    <div className={styles.author}>
      <div className={styles.avatarWrapper}>
        <Image
          src={avatarUrl}
          alt={author}
          width={50}
          height={50}
          className={styles.avatar}
        />
      </div>
      <div className={styles.authorInfo}>
        <p className={styles.name}>{author}</p>
        <Anchor
          target="_blank"
          href={githubUrl}
          className={styles.github}
        >
          {githubHandle}
        </Anchor>
      </div>
    </div>
  </div>
);

const Reviews = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 16;
  const { projectEvaluations } = useFindAllFilledEvaluations();

  const userReviews = projectEvaluations
    .map((evaluation) => ({
      reviewId: evaluation.id,
      avatar: evaluation.authorAvatarSrc,
      username: evaluation.author,
      githubUsername: evaluation.authorGithubUsername,
      projectName: evaluation.projectName,
      rating: evaluation.rating,
      reviewText: evaluation.comment,
    }))
    .sort((a, b) => b.rating - a.rating);

  const reviews = [
    {
      content: t('reviews.review1'),
      author: 'Hanna Saczuk',
      githubHandle: '@hansac91',
      githubUrl: 'https://github.com/hansac91',
      avatarUrl: '/images/testimonial-hanna-saczuk.webp',
    },
    {
      content: t('reviews.review2'),
      author: 'Małgorzata Krawczuk',
      githubHandle: '@transcendence12',
      githubUrl: 'https://github.com/transcendence12',
      avatarUrl: '/images/testimonial-malgorzata-krawczuk.webp',
    },
    {
      content: t('reviews.review3'),
      author: 'Norbert Wawrzonek',
      githubHandle: '@wawrzonnn',
      githubUrl: 'https://github.com/wawrzonnn',
      avatarUrl: '/images/testimonial-norbert-wawrzonek.webp',
    },
    {
      content: t('reviews.review4'),
      author: 'Kamil Lisiecki',
      githubHandle: '@mr-fox93',
      githubUrl: 'https://github.com/mr-fox93',
      avatarUrl: '/images/testimonial-kamil-lisiecki.webp',
    },
    {
      content: t('reviews.review5'),
      author: 'Krystian Więcek',
      githubHandle: '@Wiecek-K',
      githubUrl: 'https://github.com/Wiecek-K',
      avatarUrl: '/images/testimonial-krystian-wiecek.webp',
    },
    {
      content: t('reviews.review6'),
      author: 'Filip Was',
      githubHandle: '@filwas',
      githubUrl: 'https://github.com/filwas',
      avatarUrl: '/images/testimonial-filip-was.jpeg',
    },
    {
      content: t('reviews.review7'),
      author: 'Ania Piotrowiak Wołosiuk',
      githubHandle: '@aniawolpiuk',
      githubUrl: 'https://github.com/AniaPiWo',
      avatarUrl: '/images/testimonial-ania-piotrowiak.webp',
    },
    {
      content: t('reviews.review8'),
      author: 'Mateusz Matwiejczuk',
      githubHandle: '@mmcode9407',
      githubUrl: 'https://github.com/mmcode9407',
      avatarUrl: '/images/testimonial-mateusz-matwiejczuk.webp',
    },
    {
      content: t('reviews.review9'),
      author: 'Michał Czajkowski',
      githubHandle: '@meczajkowski',
      githubUrl: 'https://github.com/meczajkowski',
      avatarUrl: '/images/testimonial-michal-czajkowski.webp',
    },
  ];

  const totalPages = Math.ceil(userReviews.length / reviewsPerPage);
  const displayedProjectReviews = userReviews.slice(
    currentPage * reviewsPerPage,
    (currentPage + 1) * reviewsPerPage
  );

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  return (
    <section id="reviews" className={styles.reviews}>
      <Container>
        <div className={styles.content}>
          <span className={styles.subtitle}>
            {t('reviews.subtitle')}
          </span>
          <h2 className={styles.title}>{t('reviews.title')}</h2>
          <div className={styles.grid}>
            {reviews.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>

          <div className={styles.projectReviewsSection}>
            <div className={styles.projectReviewsGrid}>
              {displayedProjectReviews.map((review, index) => (
                <div key={index} className={styles.projectReviewCard}>
                  <UserReview {...review} />
                </div>
              ))}
            </div>

            <div className={styles.pagination}>
              <button
                onClick={handlePrevPage}
                className={styles.paginationButton}
                disabled={currentPage === 0}
              >
                ←
              </button>
              <span className={styles.paginationInfo}>
                {currentPage + 1} / {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className={styles.paginationButton}
                disabled={currentPage === totalPages - 1}
              >
                →
              </button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Reviews;
