import { Container } from '@/components/Container/Container';

import { AnchorList } from '@/components/AnchorList/AnchorList';

import { LinkedIn } from '@/components/Icons/LinkedIn';
import { LogoGithub } from '@/components/Icons/LogoGithub';
import { LogoInstagram } from '@/components/Icons/LogoInstagram';

import { AppRoute } from '@/config/routes';
import { useRouter } from 'next/router';

import { LogoNerdbord } from '@/components/Icons/LogoNerdbord';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import * as styles from './PageFooter.styles';

export const productLinks = [
  { name: 'Our method', href: AppRoute.home + '#method' },
  { name: 'How it works', href: AppRoute.home + '#how-it-works' },
  { name: 'FAQ', href: AppRoute.home + '#faq' },
];

const resourcesLinks = [
  {
    name: 'Privacy Policy',
    href: '/docs/privacy-policy.pdf',
  },
  {
    name: 'Terms of service',
    href: '/docs/terms-conditions.pdf',
  },
];

const communityLinks = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/nerdbord',
  },
  {
    name: 'Join our Discord',
    href: 'https://discord.gg/W7UJVJGD7Y',
  },
];

export const NerdbordPageFooter = () => {
  const router = useRouter();
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const pageMap = [
    {
      name: t('navbar.how-it-works'),
      href: AppRoute.home + '#how-it-works',
    },
    {
      name: t('navbar.reviews'),
      href: AppRoute.home + '#reviews',
    },
    {
      name: t('navbar.who-we-are'),
      href: AppRoute.home + '#who-we-are',
    },
    { name: t('navbar.faq'), href: AppRoute.home + '#faq' },
  ];

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerLinkListsLayout}>
          <div>
            <LogoNerdbord
              onClick={() => router.push(AppRoute.home)}
            />
            <p className={styles.copyright}>
              Copyright © {new Date().getFullYear()} <br /> Nerdbord
              OÜ All rights reserved.
            </p>
          </div>

          {/*{isMobile && <SocialIcons />}*/}

          <AnchorList
            title={t('navbar.platform')}
            anchors={pageMap}
          />

          <AnchorList
            title={t('navbar.resources')}
            anchors={resourcesLinks}
          />

          <AnchorList
            title={t('navbar.follow-us')}
            anchors={communityLinks}
          />
        </div>
      </Container>
    </footer>
  );
};

export const SocialIcons = () => (
  <ul className={styles.socialIcons}>
    <li>
      <a
        rel={'noreferrer'}
        target={'_blank'}
        href="https://www.linkedin.com/company/nerdbord"
      >
        <LinkedIn />
      </a>
    </li>
    <li>
      <a
        rel={'noreferrer'}
        target={'_blank'}
        href="https://github.com/nerdbord"
      >
        <LogoGithub scale={70} />
      </a>
    </li>
    <li>
      <a
        rel={'noreferrer'}
        target={'_blank'}
        href="https://www.instagram.com/nerdbord/"
      >
        <LogoInstagram />
      </a>
    </li>
  </ul>
);
