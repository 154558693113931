import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@/components/Button/Button';

import { AppRoute } from '@/config/routes';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useRouter } from 'next/router';

import { MobileMenu } from '@/components/MobileMenu/MobileMenu';

import { AnchorActiveLink } from '@/components/AnchorActiveLink/AnchorActiveLink';
import { Container } from '@/components/Container/Container';
import { LogoNerdbord } from '@/components/Icons/LogoNerdbord';
import { useGithubAuth } from '@/hooks/useGithubAuth';
import * as styles from '@/landing/components-legacy/PageHeader/PageHeader.styles';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export enum HeaderVariant {
  Default = 'default',
  Course = 'course',
}

interface NerdbordPageHeaderProps {
  variant?: HeaderVariant;
}

export const NerdbordPageHeader = ({
  variant = HeaderVariant.Default,
}: NerdbordPageHeaderProps) => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { handleAuthByGithub, isAuthorizing } = useGithubAuth();
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const [hasScrolled, setHasScrolled] = useState(
    variant === HeaderVariant.Course
  );

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    variant === HeaderVariant.Default &&
      window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const pageMap = [
    {
      name: t('navbar.how-it-works'),
      href: AppRoute.home + '#how-it-works',
    },
    {
      name: t('navbar.reviews'),
      href: AppRoute.home + '#reviews',
    },
    {
      name: t('navbar.who-we-are'),
      href: AppRoute.home + '#who-we-are',
    },
    { name: t('navbar.faq'), href: AppRoute.home + '#faq' },
    {
      name: t('navbar.join-discord'),
      href: 'https://discord.gg/W7UJVJGD7Y',
    },
  ];

  const router = useRouter();

  const handleJoinClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const programsSection = document.querySelector('#our-programs');
    if (programsSection) {
      programsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header
      className={`${styles.header} ${
        hasScrolled ? styles.headerScrolled : ''
      }`}
    >
      <Container>
        <div className={styles.headerWrapper}>
          <div className={styles.headerPagesNav}>
            <LogoNerdbord
              inverted={!hasScrolled}
              onClick={() => router.push(AppRoute.home)}
            />
            <ul className={styles.pagesNavList}>
              {pageMap.map((link) => (
                <li key={link.name}>
                  <AnchorActiveLink href={link.href}>
                    {link.name}
                  </AnchorActiveLink>
                </li>
              ))}
            </ul>
          </div>
          <nav className={styles.headerNav}>
            {isMobile && variant !== HeaderVariant.Course && (
              <Button
                onClick={() => router.push(AppRoute.login)}
                size={ButtonSize.small}
              >
                Login
              </Button>
            )}

            {isDesktop && variant !== HeaderVariant.Course && (
              <>
                <Button
                  size={ButtonSize.small}
                  disabled={isAuthorizing}
                  onClick={handleJoinClick}
                  variant={ButtonVariant.orange}
                >
                  {t('join.join-for-free')}
                </Button>
                <Button
                  onClick={() => router.push(AppRoute.login)}
                  size={ButtonSize.small}
                  variant={ButtonVariant.secondary}
                >
                  {t('navbar.login-with-github')}
                </Button>
              </>
            )}

            {isMobile && <MobileMenu menuItems={pageMap} />}
          </nav>
        </div>
      </Container>
    </header>
  );
};
