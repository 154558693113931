import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import styles from './Reinvented.module.scss';
import ReinventedSide from './ReinventedFeatures';
import {
  AiPoweredImages,
  CertificateImages,
  CodeReviewVideo,
  StartProjectImages,
} from './ReinventedImages';

const cx = classNames.bind(styles);

const Reinvented = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const reinventedFeatures = useMemo(
    () => [
      {
        title: t('reinvented.learn-javascript-by-building-products'),
        content: t(
          'reinvented.nerdbord-is-your-projects-source-and-management-tool'
        ),
        side: 'left',
        image: <StartProjectImages />,
      },
      {
        title: t('reinvented.all-of-your-code-is-instantly-reviewed'),
        content: t('reinvented.choose-your-project-and-start-coding'),
        side: 'right',
        image: <CodeReviewVideo />,
      },
      {
        title: t('reinvented.never-lose-motivation'),
        content: t(
          'reinvented.learning-with-game-mechanisms-is-effective'
        ),
        side: 'left',
        image: <AiPoweredImages />,
      },
      {
        title: t(
          'reinvented.weekly-live-collaboration-and-feedback-meetings'
        ),
        content: t(
          'reinvented.actively-engage-with-your-team-and-mentor'
        ),
        side: 'right',
        image: <CertificateImages />,
      },
      // {
      //   title: t('reinvented.learn-real-teamwork-on-group-projects'),
      //   content: t(
      //     'reinvented.experience-hands-on-learning-and-enhance-teamwork'
      //   ),
      //   side: 'left',
      //   image: <PairCodingImages />,
      // },
    ],
    [t]
  );

  return (
    <section id="how-it-works" className={styles.reinvented}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div className={cx(TypographyClasses.container)}>
          <p className={cx(TypographyClasses.subtitle)}>
            {t('reinvented.meet-our-method')}
          </p>
          <h2 className={cx(TypographyClasses.title)}>
            {t('reinvented.reinvented-learning-experience')}
          </h2>
          <p className={cx(TypographyClasses.subheader, 'fw-500')}>
            {t(
              'reinvented.we-transformed-learning-into-a-gamified-work-environment'
            )}
          </p>
        </div>
        {reinventedFeatures.map((reinvented, idx) => (
          <ReinventedSide
            key={idx}
            title={reinvented.title}
            content={reinvented.content}
            side={reinvented.side as 'left' | 'right'}
            image={reinvented.image}
          />
        ))}
      </div>
    </section>
  );
};

export default Reinvented;
