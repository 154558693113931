import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@/components/Button/Button';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import format from 'date-fns/format';
import styles from './SingleProgramGroup.module.scss';

interface SingleProgramGroupProps {
  title: string;
  price: number;
  currency: string;
  showPrice?: boolean;
  isSubscription?: boolean;
  groupPageUrl: string;
  imageSrc: string | null;
  shortDescription: string;
  endDate?: string | null;
  startAt?: string | null;
  tags?: string[];
}

export const SingleProgramGroup = ({
  showPrice = true,
  ...props
}: SingleProgramGroupProps) => {
  const router = useRouter();
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const endDate = props.endDate
    ? new Date(Number(props.endDate))
    : new Date();
  const startDate = props.startAt
    ? new Date(Number(props.startAt))
    : new Date();

  const formattedEndDate = format(endDate, 'dd.MM.yyyy');
  const formattedStartDate = format(startDate, 'dd.MM.yyyy');

  const { isMobile } = useMediaQuery();

  const getDiscountFromTags = () => {
    if (!props.tags) return null;

    const discountTag = props.tags.find(
      (tag) => tag.startsWith('-') && tag.endsWith('%')
    );

    if (!discountTag) return null;

    return parseInt(discountTag.replace(/[-%]/g, ''));
  };

  const renderPrice = () => {
    if (props.price === 0) return t('singleprogramgroup.free');

    const discount = getDiscountFromTags();
    const monthlyText = props.isSubscription
      ? `/ ${t('singleprogramgroup.month')}`
      : '';

    if (discount) {
      const originalPrice = Math.round(
        props.price / (1 - discount / 100)
      );
      return (
        <>
          <span className={styles.currentPrice}>
            {props.price} {props.currency} {monthlyText}
          </span>
          <span className={styles.originalPrice}>
            {originalPrice} {props.currency}
          </span>
          <span className={styles.discountBadge}>-{discount}%</span>
        </>
      );
    }

    return (
      <>
        {props.price} {props.currency} {monthlyText}
      </>
    );
  };

  return (
    <div
      onClick={() => router.push(props.groupPageUrl)}
      className={styles.singleProgram}
    >
      <div className={styles.imageWrapper}>
        {props.imageSrc && (
          <Image
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
            alt={props.shortDescription}
            src={props.imageSrc}
          />
        )}
      </div>
      <div className={styles.programData}>
        <h2 className={styles.title}>{props.title}</h2>
        <p className={styles.shortDescription}>
          {props.shortDescription}
        </p>
        <nav>
          <div className={styles.nav}>
            {showPrice && (
              <span className={styles.chip}>{renderPrice()}</span>
            )}
            {props.endDate && (
              <span className={styles.highlightedPill}>
                {t('singleprogramgroup.availableto')}{' '}
                {formattedEndDate}
              </span>
            )}
            {props.startAt && (
              <span className={styles.highlightedPill}>
                Start {formattedStartDate}
              </span>
            )}
          </div>
          <Button
            variant={ButtonVariant.orange}
            fullWidth={isMobile}
            size={ButtonSize.large}
            onClick={() => router.push(props.groupPageUrl)}
          >
            {t('singleprogramgroup.checkitout')}
          </Button>
        </nav>
      </div>
    </div>
  );
};
