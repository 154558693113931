import { TypographyClasses } from '@/landing/styles/tokens';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import classNames from 'classnames/bind';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Faq.module.scss';
import FaqItem from './FaqItem';

const cx = classNames.bind(styles);

const Faq = () => {
  const tabRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(I18nNamespaces.Homepage);

  const [isVisible, setIsVisible] = useState(false);

  const faqs = useMemo(
    () => [
      {
        header: t('faq.header1'),
        text: t('faq.text1'),
      },
      {
        header: t('faq.header2'),
        text: t('faq.text2'),
      },
      {
        header: t('faq.header4'),
        text: t('faq.text4'),
      },
      {
        header: t('faq.header3'),
        text: t('faq.text3'),
      },
      {
        header: 'W jakim języku muszę umieć programować?',
        text: 'Tak naprawdę w dowolnym, który umożliwi Ci odpytywanie API. Ten kurs nie zakłada budowania front-endu, także znajomość HTML, CSS czy Reacta nie będzie Ci potrzebna. Ja na co dzień programuje w Typescript, także przykłady oraz gotowy kod z rozwiązaniem będzie właśnie w tym języku. Jeżeli programujesz np. w Pythonie, to wystarczy, że użyjesz AI do przetłumaczenia mojego kodu na język, którym najlepiej władasz :)',
      },
      {
        header: 'Ile mam czasu na ukończenie kursu?',
        text: 'Na ukończenie kursu będziesz mieć 30 dni od momentu jego uruchomienia. Po tym czasie nie otrzymasz certyfikatu, a kurs na platformie zostanie zablokowany. Będziesz mieć natomiast możliwość dokończenia kursu w swoim czasie.',
      },
      {
        header: 'Czy mogę pobrać gotowy kod?',
        text: 'Tak! Jeśli chcesz zobaczyć, jak wygląda pełne rozwiązanie w TypeScript, możesz kupić dostęp do repozytorium z działającym kodem. To świetna opcja, jeśli chcesz porównać swoją implementację z moim rozwiązaniem lub po prostu zaoszczędzić czas.',
      },
      {
        header: 'Jak są zasady otrzymania certyfikatu?',
        text: 'Potrzebujesz ukończyć wszystkie zadania w czasie mniejszym niż 30 dni, aby otrzymać certyfikat.',
      },
      {
        header: 'Gdzie odbywa się komunikacja ze społecznością?',
        text: 'Na Discordzie - po dołączeniu do kursu otrzymasz wszystkie potrzebne informacje oraz instrukcje jak tam dołączyć.',
      },
      {
        header: 'Jakie narzędzia muszę mieć żeby zbudować ten kurs?',
        text: 'Potrzebujesz edytora (IDE), w którym będziesz pisać kod oraz konto na Github, na którym zostanie wygenerowane repozytorium, na którym będziesz pracować.',
      },
    ],
    [t]
  );

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }, observerOptions);

    if (tabRef.current) {
      observer.observe(tabRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section id="faq" className={cx('faq')} ref={tabRef}>
      <div className={cx(TypographyClasses.wrapper)}>
        <div>
          <div
            className={`${cx(`${TypographyClasses.container}`)} ${
              isVisible ? cx('sticky') : ''
            }`}
          >
            <p className={cx(TypographyClasses.subheader)}>
              {t('faq.subheader')}
            </p>
            <h2 className={cx(TypographyClasses.title)}>
              {t('faq.title')}
            </h2>
            <p className={cx(TypographyClasses.subtitle)}>
              {t('faq.subtitle')}
            </p>
            <div className={cx('contact-info')}>
              <div className={cx('contact-avatar')}></div>
              <div className={cx('contact-details')}>
                <h4 className={cx('contact-name')}>Jakub Wąsowski</h4>
                <p className={cx('contact-role')}>
                  Trener i Założyciel Nerdborda
                </p>
                <a
                  href="mailto:jakub@nerdbord.io"
                  className={cx('contact-email')}
                >
                  jakub@nerdbord.io
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(TypographyClasses.content)}>
          {faqs.map((faq, index) => {
            return (
              <FaqItem
                key={index}
                header={faq.header}
                text={faq.text}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
