import { PropsWithChildren } from 'react';

import { UserEntity } from '@nerdbord/graphql-api';
import Head from 'next/head';
import { NerdbordPageFooter } from '../../components-legacy/PageFooter/NerdbordPageFooter';
import {
  HeaderVariant,
  NerdbordPageHeader,
} from '../../components-legacy/PageHeader/NerdbordPageHeader';

interface PageLayoutProps {
  pageTitle: string;
  metaDesc: string;
  ogImageSrc?: string | null;
  user: UserEntity | null;
  headerVariant?: HeaderVariant;
}

export const PageLayout = (
  props: PropsWithChildren<PageLayoutProps>
) => {
  return (
    <>
      <Head>
        <title>{props.pageTitle}</title>
        <meta name="description" content={props.metaDesc} />
        <meta property="og:title" content={props.pageTitle} />
        <meta property="og:description" content={props.metaDesc} />
        {props.ogImageSrc && (
          <meta property="og:image" content={props.ogImageSrc} />
        )}
        {props.ogImageSrc && (
          <meta property="og:image:url" content={props.ogImageSrc} />
        )}
        <meta
          property="og:image:alt"
          content="Learn how to build GenAI projects and grow your next generation skills"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <link rel="icon" href="/favicon/favicon.ico" />
      </Head>
      <NerdbordPageHeader variant={props.headerVariant} />
      <main>{props.children}</main>
      <NerdbordPageFooter />
    </>
  );
};
