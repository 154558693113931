import { Avatar } from '@/components/Avatar/Avatar';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import React from 'react';

import { InputCheckbox } from '@/components/Input';
import * as styles from './UserReview.styles';

export type UserReviewProps = {
  avatar: string;
  username: string;
  githubUsername: string;
  projectName: string;
  rating: number;
  reviewText?: string;
  reviewId: string;
  isPublic?: boolean;
  onSetEvaluationPublic?(isPublic: boolean): void;
};

export const UserReview: React.FC<UserReviewProps> = ({
  avatar,
  username,
  projectName,
  rating,
  reviewText,
  githubUsername,
  isPublic,
  onSetEvaluationPublic,
}) => {
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

  const { isMobile } = useMediaQuery();

  const handleAvatarClick = () => {
    window.open('https://github.com/' + githubUsername, '_blank');
  };

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.avatarGroup}>
        <Avatar
          size={isMobile ? 30 : 50}
          desc={username}
          imgSrc={avatar}
          onClick={handleAvatarClick}
        />
        {isMobile && (
          <div className={styles.userNameContainer}>
            <span
              onClick={handleAvatarClick}
              className={styles.userNameStyle}
            >
              @{githubUsername}
            </span>
          </div>
        )}
      </div>
      <div>
        <div className={styles.elementsGroup}>
          {!isMobile && (
            <div className={styles.userNameContainer}>
              <span
                onClick={handleAvatarClick}
                className={styles.userNameStyle}
              >
                @{githubUsername}
              </span>
            </div>
          )}
          <span className={styles.roleStyle}>{projectName}</span>
          <div className={styles.ratingStyle}>
            {Array.from({ length: filledStars }, (_, i) => (
              <span className={styles.star(true)} key={`full-${i}`}>
                ★
              </span>
            ))}
            {hasHalfStar && (
              <span className={styles.star(false)}>☆</span>
            )}
            {Array.from({ length: emptyStars }, (_, i) => (
              <span className={styles.star(false)} key={`empty-${i}`}>
                ☆
              </span>
            ))}
          </div>
          {onSetEvaluationPublic && (
            <InputCheckbox
              checked={!!isPublic}
              onChange={() => {
                onSetEvaluationPublic(!isPublic);
              }}
            />
          )}
        </div>
        <p className={styles.reviewTextStyle}>
          {reviewText || (
            <span>Użytkownik nie dodał szczegółowej opinii.</span>
          )}
        </p>
      </div>
    </div>
  );
};
