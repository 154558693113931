import { Container } from '@/components/Container/Container';
import { GroupProgram, PaymentMode } from '@nerdbord/graphql-api';
import { SingleProgramGroup } from '../../SingleProgramGroup/SingleProgramGroup';
import styles from './Programs.module.scss';

interface ProgramsProps {
  programs: GroupProgram[];
}

export const Programs = (props: ProgramsProps) => {
  const highlightedPrograms = props.programs.filter((program) =>
    program.tags.some((tag) => tag === 'Campaign')
  );

  const nonHighlightedPrograms = props.programs.filter(
    (program) => !program.tags.some((tag) => tag === 'Campaign')
  );

  return (
    <section id={'our-programs'} className={styles.programs}>
      <Container>
        <div className={styles.programsGrid}>
          {highlightedPrograms.map((program, idx) => (
            <SingleProgramGroup
              key={idx}
              groupPageUrl={'/g/' + program.slug}
              currency={program.currency}
              imageSrc={program.coverImageSrc}
              showPrice={program.showPrice}
              price={program.pricePerSeat}
              isSubscription={
                program.paymentMode === PaymentMode.SUBSCRIPTION
              }
              startAt={program?.startAt || null}
              endDate={program?.endAt || null}
              title={program.name}
              shortDescription={program.shortDsc}
              tags={program.tags}
            />
          ))}
          {nonHighlightedPrograms.map((program, idx) => (
            <SingleProgramGroup
              key={idx}
              groupPageUrl={'/g/' + program.slug}
              currency={program.currency}
              showPrice={program.showPrice}
              imageSrc={program.coverImageSrc}
              price={program.pricePerSeat}
              startAt={program?.startAt || null}
              isSubscription={
                program.paymentMode === PaymentMode.SUBSCRIPTION
              }
              endDate={program?.endAt || null}
              title={program.name}
              shortDescription={program.shortDsc}
              tags={program.tags}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
