import { CookieInfo } from '@/components/CookieInfo/CookieInfo';
import { PageLayout } from '@/landing/components/PageLayout/PageLayout';
import Faq from '@/landing/components/sections/Faq/Faq';
import Hero from '@/landing/components/sections/Hero/Hero';
import Industry from '@/landing/components/sections/Industry/Industry';
import InTouch from '@/landing/components/sections/InTouch/InTouch';
import Join from '@/landing/components/sections/Join/Join';
import Portfolio from '@/landing/components/sections/Portfolio/Portfolio';
import { Programs } from '@/landing/components/sections/Programs/Programs';
import Reinvented from '@/landing/components/sections/Reinvented/Reinvented';
import Reviews from '@/landing/components/sections/Reviews/Reviews';
import Statistics from '@/landing/components/sections/Statistics/Statistics';
import { WhoWeAre } from '@/landing/components/sections/WhoWeAre/WhoWeAre';
import withApollo, {
  createSsrApolloClient,
} from '@/lib/apollo-graphql/withApollo';
import { authUser } from '@/utils/auth';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import {
  FIND_PUBLIC_GROUPS,
  GroupProgram,
  mapGroupEntityToGroupProgram,
  UserEntity,
} from '@nerdbord/graphql-api';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

interface HomeProps {
  programs: GroupProgram[];
  user: UserEntity | null;
}

function Home(props: HomeProps) {
  return (
    <PageLayout
      pageTitle={
        'Nerdbord - learn how to build AI products and tools'
      }
      metaDesc={
        'Nerdbord is a platform for learning how to build AI products through hands-on practice.'
      }
      ogImageSrc={'/images/nb-og-2025.jpg'}
      user={props.user}
    >
      <Hero />
      <Programs programs={props.programs} />
      <Statistics />
      <Industry />
      <Reinvented />
      {/* <NerdbordFor /> */}
      {/* <Testimonial1 /> */}
      <Portfolio />
      <Reviews />
      {/* <Appreciated /> */}
      {/* <ProjectsAnatomy /> */}
      <Join />
      {/* <Testimonial2 /> */}

      <WhoWeAre />
      {/* <Testimonial3 /> */}
      <Faq />
      <InTouch />
      <CookieInfo cookiesTokenKey={'cookie-consent'} />
    </PageLayout>
  );
}

export async function getServerSideProps({
  locale,
  ...context
}: GetServerSidePropsContext) {
  const apolloClient = createSsrApolloClient(context);

  try {
    const { data } = await apolloClient.query({
      query: FIND_PUBLIC_GROUPS,
    });

    const user = await authUser(context, apolloClient);

    const programs: GroupProgram[] = data.findPublicGroups.map(
      mapGroupEntityToGroupProgram
    );

    return {
      props: {
        user,
        programs,
        ...(locale &&
          (await serverSideTranslations(locale, [
            I18nNamespaces.Homepage,
          ]))),
      },
    };
  } catch (err) {
    console.log('err', err);
    return {
      props: {
        ...(locale &&
          (await serverSideTranslations(locale, [
            I18nNamespaces.Homepage,
          ]))),
        programs: [],
        user: null,
      },
    };
  }
}

export default withApollo(Home);
