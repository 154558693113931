import { Container } from '@/components/Container/Container';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useGetStatistics } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import styles from './Statistics.module.scss';

const Statistics = () => {
  const { t } = useTranslation(I18nNamespaces.Homepage);
  const { statistics } = useGetStatistics();

  return (
    <section className={styles.statistics}>
      <Container>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('statistics.title')}</h2>
          <div className={styles.statsGrid}>
            <div className={styles.statItem}>
              <span className={styles.statNumber}>
                {statistics?.completedProjects}
              </span>
              <span className={styles.statLabel}>
                {t('statistics.completed-projects')}
              </span>
            </div>

            <div className={styles.statItem}>
              <span className={styles.statNumber}>
                {statistics?.averageRating?.toFixed(2)}/5
              </span>
              <span className={styles.statLabel}>
                {t('statistics.average-rating')}
              </span>
            </div>

            <div className={styles.statItem}>
              <span className={styles.statNumber}>
                {statistics?.activeUsers}
              </span>
              <span className={styles.statLabel}>
                {t('statistics.active-users')}
              </span>
            </div>

            <div className={styles.statItem}>
              <span className={styles.statNumber}>
                {statistics?.codeReviews}
              </span>
              <span className={styles.statLabel}>
                {t('statistics.code-reviews')}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Statistics;
